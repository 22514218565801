<template>

    <b-row>

        <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-bottom: 10px;">
                <p style="text-align: left;margin-bottom: 5px;"> Seleccione el tipo de transacción (*):</p>
                <b-skeleton v-if="loading"  width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>

            <b-form-select
            v-else
            @change="onChangeTransaccion()"
                v-model="typeTransaction"
                :options="options1"
                />
        </b-col>

         <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-bottom:10px"  >
               <p style="text-align: left;margin-bottom: 5px;"> Ingrese el monto de transacción (*):</p>
               <b-skeleton  v-if="loading" width="100%" style="margin-top:0px;margin-bottom:0px" height="40px"></b-skeleton>
               <b-form-input
              v-else
              v-model="monto"
              placeholder="Ingrese una cantidad"
              required
              ref="focusMe"
              @keypress="NumbersOnly"
              @input="checkMount2"
                @blur="checkMount"
              type="tel"
              :readonly="isDisabledMount"
            />
        </b-col>


        <b-col
        cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom: 10px;"
            >

                <b-alert
              variant="danger"
              v-if="errorMount"
              show
            >
              <div class="alert-body">
                <p style="margin:0; text-align: center;font-size:12px;font-weight: bold;"> {{error}}</p>
              
              </div>
            </b-alert>

            <b-alert v-if="isCorrectValue" variant="primary" style="margin-bottom: 10px !important;" show>
              <div class="alert-body">

                 <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
              >
              Monto a repartir aplicando la comisión del {{comisionEQCoop}}% de EQCoop
              </p>

         

                        <p style="font-size: 18px;text-align:center; margin-bottom:0;font-weight: bold;">$ {{resultadoComision}} USD</p>


              
            
              
              </div>
            </b-alert>

            </b-col>

         <b-col cols="6" sm="6" md="4" lg="4" xl="4" style="margin-bottom:10px" >
             <p style="text-align: left;margin-bottom: 5px;"> Comisión 1er Nivel: </p>
             <b-skeleton   v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="40px"></b-skeleton>
                     <b-form-input
                     v-else
                         
                        v-model="comision1"
                        placeholder="$0.00"
                        type="text"
                        :readonly="true"

                        />
                        <b-skeleton   v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="10px"></b-skeleton>
                        <p v-else style="text-align: left;margin-bottom: 0px;font-size:11px"> Aplica ganancia del {{comisionTransaccionPrimerNivel}}% </p>
        </b-col>

        <b-col cols="6" sm="6" md="4" lg="4" xl="4" style="margin-bottom:10px" >
             <p style="text-align: left;margin-bottom: 5px;"> Comisión 2do Nivel: </p>
             <b-skeleton   v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="40px"></b-skeleton>
                     <b-form-input
                     v-else
                         
                        v-model="comision2"
                        placeholder="$0.00"
                        type="text"
                        :readonly="true"

                        />
                        <b-skeleton   v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="10px"></b-skeleton>
                        <p v-else style="text-align: left;margin-bottom: 0px;font-size:11px"> Aplica ganancia del {{comisionTransaccionSegundoNivel}}% </p>
        </b-col>

        <b-col cols="12" sm="12" md="4" lg="4" xl="4"  style="margin-bottom:10px">
             <p style="text-align: left;margin-bottom: 5px;"> Comisión 3er Nivel: </p>
             <b-skeleton   v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="40px"></b-skeleton>
                     <b-form-input
                     v-else
                         
                        v-model="comision3"
                        placeholder="$0.00"
                        type="text"
                        :readonly="true"

                        />

                        <b-skeleton   v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="10px"></b-skeleton>
                        <p v-else style="text-align: left;margin-bottom: 0px;font-size:11px"> Aplica ganancia del {{comisionTransaccionTercerNivel}}% </p>
        </b-col>

       

        
    </b-row>

 


</template>

<script>
import {
BButton, BSpinner, BRow, BCol, BCard, BFormInput, BFormSelect,VBPopover,BSkeleton, BAlert
} from 'bootstrap-vue'



export default {
components: {
BButton,
BAlert,
BSkeleton,
BRow,
BCol,
BCard,
BSpinner,
BFormSelect,
BFormInput,
},
directives: {
'b-popover': VBPopover,
},
props: ['userId','tokenAuth'],
data() {


return {

loading: true,
monto:"",
typeTransaction:null,
comision1:"$0.00",
comision2:"$0.00",
comision3:"$0.00",
isDisabledMount:true,
mountMinimo:0,
errorMount:false,
isCorrectValue:false,
error:"",
comisionTransaccionPrimerNivel:0,
comisionTransaccionSegundoNivel:0,
comisionTransaccionTercerNivel:0,
comisionBuyCripto:0,
resultadoComision:0,
comisionRetiroCripto:0,
comisionEQCoop:0,
options1: [
{ value: null, text: 'Seleccione una opción' },
{ value: 'cambio', text: 'Cambio de criptomonedas a dólares' },
{ value: 'compra', text: 'Compra de criptomonedas' },

], 



}
},
computed: {

},
watch: {

},
mounted() {

this.loadCalculadora();






},
methods: {

    checkMount2(value){
    

    if(value === ""){

       
    

    this.comision1="$0.00";
     this.comision2="$0.00";
     this.comision3="$0.00";
  

          this.errorMount= false;
          this.isCorrectValue=false;
     




       }else{


          if(Number(value) < Number(this.mountMinimo)){

           this.errorMount= true;
           this.error="Para esta transacción, el monto ingresado debe ser mayor a $"+this.mountMinimo+" USD";
           this.comision1="$0.00";
            this.comision2="$0.00";
            this.comision3="$0.00";
            this.isCorrectValue=false;
  
     

           }else{

    

                this.errorMount= false;
                this.isCorrectValue=true;
                this.error="";

            
                   
         
                if(this.typeTransaction === "cambio"){

                  this.comisionEQCoop=this.comisionRetiroCripto;


                    this.resultadoComision=(Number(value) * Number(this.comisionRetiroCripto))/ Number("100");


             
                  


                    }else{

                    if(this.typeTransaction === "compra"){

                      
                  this.comisionEQCoop=this.comisionBuyCripto;
                        this.resultadoComision=(Number(value) * Number(this.comisionBuyCripto))/ Number("100");

                    }

                }


                this.comision1="$"+Number(Number(Number(this.resultadoComision) * Number(this.comisionTransaccionPrimerNivel))/Number("100")).toFixed(2) ;
                    this.comision2="$"+Number(Number(Number(this.resultadoComision) * Number(this.comisionTransaccionSegundoNivel))/Number("100")).toFixed(2) ;
                    this.comision3="$"+Number(Number(Number(this.resultadoComision) * Number(this.comisionTransaccionTercerNivel))/Number("100")).toFixed(2) ;

             

              



           }

  

    



       

    


       }

},

checkMount(){

  var t = this.monto;



       if(t === "" ){

           this.errorMount=false;
           this.isCorrectValue=false;

           this.comision1="$0.00";
            this.comision2="$0.00";
            this.comision3="$0.00";
         




       }else{

       

         

        if(Number(t) < Number(this.mountMinimo)){

                this.errorMount= false;
                this.monto="";
                this.isCorrectValue=false;

                this.comision1="$0.00";
                this.comision2="$0.00";
                this.comision3="$0.00";
          



           }else{
            this.isCorrectValue=true;

            this.errorMount= false;
                this.error="";

            
                   
         
                if(this.typeTransaction === "cambio"){

                  this.comisionEQCoop=this.comisionRetiroCripto;
                    this.resultadoComision=(Number(t) * Number(this.comisionRetiroCripto))/ Number("100");


             
                  


                    }else{

                    if(this.typeTransaction === "compra"){
                      this.comisionEQCoop=this.comisionBuyCripto;
                        this.resultadoComision=(Number(t) * Number(this.comisionBuyCripto))/ Number("100");

                    }

                }


                this.comision1="$"+Number(Number(Number(this.resultadoComision) * Number(this.comisionTransaccionPrimerNivel))/Number("100")).toFixed(2) ;
                    this.comision2="$"+Number(Number(Number(this.resultadoComision) * Number(this.comisionTransaccionSegundoNivel))/Number("100")).toFixed(2) ;
                    this.comision3="$"+Number(Number(Number(this.resultadoComision) * Number(this.comisionTransaccionTercerNivel))/Number("100")).toFixed(2) ;



         
         }

       }




 

},

    NumbersOnly(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },

loadCalculadora(){

const userId_json = { userId: this.userId }
const user_string = JSON.stringify(userId_json)


const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

this.$https.post('/transactions/fee/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
this.loading=false;



      if(response.data["code"] == 200){
      
        

        this.comisionTransaccionSegundoNivel=response.data["comisionTransaccionSegundoNivel"];
         this.comisionTransaccionTercerNivel=response.data["comisionTransaccionTercerNivel"];
          this.comisionTransaccionPrimerNivel=response.data["comisionTransaccionPrimerNivel"];
         
          this.comisionBuyCripto=  response.data["comisionBuyCripto"];
                    this.comisionRetiroCripto= response.data["comisionRetiroCripto"];
          
        }else{

       


                  if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                    
                    localStorage.removeItem('userData')



                    
                    



                    
                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                  } else {



                    
                    this.loadCalculadora();
                  


                  }

        }
 }).catch((error) => {
  this.loadCalculadora();

     
 });

},

onChangeTransaccion(){


this.isDisabledMount=false;

this.monto="";
this.comision1="$0.00";
this.comision2="$0.00";
this.comision3="$0.00";

this.$refs.focusMe.focus()

if(this.typeTransaction === "cambio"){

  this.mountMinimo=100;


}else{

  if(this.typeTransaction === "compra"){
    this.mountMinimo=100;



  }




}









},

},
}
</script>

<style lang="scss">



</style>

<template>

    
  
    <b-row>


        <b-col v-if="!isMobile" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">
            <b-row>

                <b-col cols="12" sm="12" md="6" lg="3" xl="3">


                    <inicial />
                    </b-col>

                    <b-col cols="12" sm="12" md="6" lg="3" xl="3" >

                        <junior />

</b-col>

<b-col cols="12" sm="12" md="6" lg="3" xl="3" >

    <senior />

</b-col>

<b-col cols="12" sm="12" md="6" lg="3" xl="3" >

    <master />

</b-col>

      
           
          
          



            </b-row>

        </b-col>

        
        <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12" >

            <carousel :autoplay="false" :dots="false" :stagePadding="10" :nav="false" :items="3" :margin="5" :responsive="{0:{items:1,nav:false},600:{items:2,nav:false},770:{items:1,nav:false},1200:{items:3,nav:false}}">
    
                    <inicial />
           <junior />
           <senior />
           <master />

                
      

                </carousel>

         

</b-col>



        <b-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="!isMobile" >

            <b-row>


                <b-col cols="12" sm="12" md="4" lg="4" xl="4" align="center" >

                    <card-promotor/>


        </b-col>

        <b-col cols="12" sm="12" md="4" lg="4" xl="4" align="center" >

            <card-verificados/>

    </b-col>


    <b-col cols="12" sm="12" md="4" lg="4" xl="4" align="center" >

        <card-no-verificados/>
</b-col>



        </b-row>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center"  v-if="mensaje != 'No'" style="padding:0px; margin-bottom:10px">

                            
                                                            

            <b-alert
            variant="secondary"
            show
            style="margin-top:0px;"
            >
            <div class="alert-body">
            <p>{{mensaje}}</p>

            </div>
            </b-alert>

            </b-col>
    
    </b-col>


    <b-col cols="12" sm="12" md="6" lg="6" xl="6" v-else>

        <carousel :autoplay="false" :dots="false" :stagePadding="10" :nav="false" :items="3" :margin="5" :responsive="{0:{items:1,nav:false},600:{items:2,nav:false},770:{items:1,nav:false},1200:{items:3,nav:false}}">
    

        <card-promotor/>
        <card-verificados/>
        <card-no-verificados/>

        </carousel>
        <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center"  v-if="mensaje != 'No'" style="padding:0px; margin-bottom:10px">

                            
                                                                        

            <b-alert
            variant="secondary"
            show
            style="margin-top:0px;"
            >
            <div class="alert-body">
            <p>{{mensaje}}</p>

            </div>
            </b-alert>

            </b-col>
        </b-row>

        </b-col>

        <b-col cols="12" sm="12" md="6" lg="6" xl="6" >

                <b-card>

                    
                <h4 class="font-weight-bolder" style="text-align: left !important;margin-bottom: 15px;">
                <feather-icon
                        icon="ListIcon"
                        size="21"
                        class="color_icon"
                        /> Calculadora de comisiones por transacciones de referidos
                </h4>

                <hr style="margin:10px">

                <calculadora
                    :user-id="userId"
                    :token-auth="tokenAuth"
                />


                </b-card>

   


            </b-col>

     </b-row>


</template>

<script>
import { BRow, BCol,BAlert,BSkeleton, VBTooltip,VBPopover, BCard,BButton, BAvatar } from 'bootstrap-vue'
import Inicial from './Inicial.vue'
import Junior from './Junior.vue'
import Senior from './Senior.vue'
import Master from './Master.vue'
import Calculadora from './Calculadora.vue'
import CardPromotor from './CardPromotor.vue'
import CardVerificados from './CardVerificados.vue'
import CardNoVerificados from './CardNoVerificados.vue'
import carousel from 'vue-owl-carousel'
export default {
  components: {
    BRow,
    carousel,
    Senior,
    CardNoVerificados,
    CardVerificados,
    Calculadora,
    CardPromotor,
    BAvatar,
    Inicial,
    Master,
    Junior,
    BCard,
    BSkeleton,
    BAlert,
    BCol,
    BButton,


  },
  directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {
  
    const datos_json = JSON.parse(this.$userGlobal);


    return {

  
     
        isMobile:this.$isMobile,
      
    userId: datos_json.userId,
    tokenAuth: this.$tokenGlobal,
    mensaje:"No"
    }
  },
  created() {
    

  },
  activated() {

this.$eventBus.$emit('reiniciarMenu1')
 this.$eventBus.$emit('reiniciarMenu2')


},
  mounted() {



    document.title = 'EQCoop - Rango de promotores';

    this.getRango();

  },
  methods: {
    
    getRango(){
 


this.$https.post('/inversion/getNivel2/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
    

    if (response.data.code == 200) {


    this.nivel= response.data.nivel;
    this.icon=response.data.icon; 
    this.verificados= response.data.verificados;
    this.no_verificados= response.data.no_verificados;
    this.mensaje=response.data.mensaje;

    this.$eventBus.$emit('parametroPromotor', this.nivel,this.icon )
    this.$eventBus.$emit('parametroVerificados', this.verificados )
    this.$eventBus.$emit('parametroNoVerificados', this.no_verificados )
    
    } else {




    if (response.data.code == 401) {

        this.$toast.error(response.data.message, {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
        })
        
        localStorage.removeItem('userData')

        

        
        

        

        
        

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
    } else {
        this.getRango();
    }
    }
}).catch(error => {
    this.getRango();
})



},

  },
}
</script>

<style lang="scss" >



</style>

<template>

    
<b-card >
<div align="center">

<b-avatar
                size="65"
                variant="light-primary"
                >

                <span class="d-flex align-items-center">
                <feather-icon
                icon="UserCheckIcon"
                size="35"
                />

                </span>

                </b-avatar>


            <b-skeleton v-if="loading" width="35px" type="avatar"   height="35px" style="margin-top:5px" ></b-skeleton>

            <p v-else style="margin-bottom:0px;margin-top:10px; font-size:25px;text-align: center; font-weight: bold;"> {{verificados}}</p>

            <p style="text-align: center;font-size:12px;margin:0px">Referidos verificados</p>

            </div>

</b-card>


        
        </template>
        
        <script>
        import {
         BRow, BCol,BCard, BAvatar, BSkeleton
        } from 'bootstrap-vue'
    
        export default {
        components: {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BSkeleton
        },
        data() {
        
        
        
        return {
    
            loading:true,
            verificados:0,
     
        }
        },
        computed: {
        
        },
        watch: {
        
        
        
        },
        created(){
        
        
        
        
        
        
        },
        mounted() {
        
            this.$eventBus.$on('parametroVerificados', (verificados ) => {
          this.verificados = verificados;
          this.loading=false;
        });
        
        
        },
        methods: {
        }
        }
        
        </script>
        
        <style lang="scss">
        
        
        
        
        
        
        
        </style>
        
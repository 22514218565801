<template>

<b-card>
<b-row>

    <b-modal
      ref="modalDetalles"
      centered
      size="sm"
      hideHeaderClose
      hideHeader
      ok-only
      ok-title="Entendido"
    >
      <div class="d-block text-center">

        <b-row>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-bottom:15px ">

                <p style="margin-bottom:5px; font-weight: bold;font-size:18px">Rango inicial</p>


                <img alt="Rango inicial"  src="https://firebasestorage.googleapis.com/v0/b/eqpayapp.appspot.com/o/badge%2Frango_initial.svg?alt=media&token=7c8a3919-8f43-4067-b43f-039b057b33c8" style="width: 50px;">
            
</b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                <ul style="text-align: justify;    margin: 0px;padding-left: 25px;padding-right: 10px;">

                <li>
                        Con este rango puedes utilizar todas las funcionalidades principales de nuestra billetera de manera GRATUITA.

                </li>
                <li>
                    Comienza a compartir tu link de promotor con tus amigos y familiares para que subas de rango y desbloquees el acceso a ganar comisiones.

                </li>

                </ul>

            </b-col>




        </b-row>

  

        </div>

        </b-modal>

    <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="display:flex; margin-bottom:10px ">

        <div style="margin:auto">
            <p style="margin-bottom:5px; font-weight: bold;">Rango inicial</p>


            <img alt="Rango inicial"  src="https://firebasestorage.googleapis.com/v0/b/eqpayapp.appspot.com/o/badge%2Frango_initial.svg?alt=media&token=7c8a3919-8f43-4067-b43f-039b057b33c8" style="width: 50px;">
          

        </div>



        </b-col>

        <b-col  cols="12" sm="12" md="12" lg="12" xl="12" align="center" >
         
 
         <p class="ver_todos2" @click="showModal">         
            ¿Cómo desbloquear este rango?</p>
          
     </b-col>

     



</b-row>
</b-card>


</template>

<script>
import {
 BRow, BCol,BCard
} from 'bootstrap-vue'

export default {
components: {
BRow,
BCol,
BCard,
},

data() {



return {

}
},
computed: {

},
watch: {



},
created(){






},
mounted() {



},
methods: {

    showModal(){
        this.$refs.modalDetalles.show();
    }
}
}

</script>

<style lang="scss">







</style>

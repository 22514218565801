<template>

    
<b-card>
<b-row>

    <b-modal
      ref="modalDetalles"
      centered
      size="sm"
      ok-only
      hideHeaderClose
      hideHeader
      ok-title="Entendido"
    >
      <div class="d-block text-center">

        <b-row>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-bottom:15px ">

                <p style="margin-bottom:5px; font-weight: bold;font-size:18px">Rango senior</p>


            <img alt="Rango senior"  src="https://firebasestorage.googleapis.com/v0/b/eqpayapp.appspot.com/o/badge%2Fsenior.svg?alt=media&token=ea12ef0e-fc78-4ffd-9c93-2a8a085bc256" style="width: 50px;">

            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                <ul style="text-align: justify;    margin: 0px;padding-left: 25px;padding-right: 10px;">
<li>
Debes de tener mínimo 10 personas referidas y verificadas para activar este nivel.



</li>
<li>
Gana comisiones de hasta tu segundo nivel por las transacciones que ellos realicen.


</li>

<li>
Obtén acceso a capacitaciones dentro de nuestra academia de formación.


</li>

<li>
Afilia negocios y comisiona el 10% de su suscripción mensual.


</li>

</ul>


               

            </b-col>




        </b-row>

  

        </div>

        </b-modal>


    <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="display:flex; margin-bottom:10px ">

        <div style="margin:auto">
            <p style="margin-bottom:5px; font-weight: bold;">Rango senior</p>


        <img alt="Rango senior"  src="https://firebasestorage.googleapis.com/v0/b/eqpayapp.appspot.com/o/badge%2Fsenior.svg?alt=media&token=ea12ef0e-fc78-4ffd-9c93-2a8a085bc256" style="width: 50px;">
    

        </div>



        </b-col>

     
        <b-col  cols="12" sm="12" md="12" lg="12" xl="12" align="center" >
         
 
         <p class="ver_todos2" @click="showModal">         
            ¿Cómo desbloquear este rango?</p>
          
     </b-col>


    </b-row>

</b-card>

        
        
        </template>
        
        <script>
        import {
         BRow, BCol,BCard
        } from 'bootstrap-vue'
   
        
        export default {
        components: {
        BRow,
        BCard,
        BCol,
        },
        
        data() {
        
        
        
        return {
 
        }
        },
        computed: {
        
        },
        watch: {
        
        
        
        },
        created(){
        
        
        
        
        
        
        },
        mounted() {
        
        
        
        },
        methods: {

            showModal(){
        this.$refs.modalDetalles.show();
    }
        }
        }
        
        </script>
        
        <style lang="scss">
        
        
        
        
        
        
        
        </style>
        
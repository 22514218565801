<template>

<b-card >
<div align="center">


<b-skeleton v-if="loading" width="75px" type="avatar"   height="75px" ></b-skeleton>

    <b-avatar
    v-else
        size="65"
        variant="light-primary"

        >

        <span class="d-flex align-items-center">
            <img alt="Rango de promotor"  :src="icon" style="width: 45px;">

        
        </span>

        </b-avatar>

        <b-skeleton v-if="loading" width="100%"  height="20px" style="margin-top:10px; margin-bottom:0px" ></b-skeleton>
        <p v-else style="margin-bottom:0px;margin-top:10px; font-size:16px; font-weight: bold;">Promotor {{nivel}}</p>
        <p  style="margin-bottom:0px;margin-top:0px; font-size:12px;">Rango actual</p>

    </div>

</b-card>
    
    </template>
    
    <script>
    import {
     BRow, BCol,BCard, BAvatar, BSkeleton
    } from 'bootstrap-vue'

    export default {
    components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BSkeleton
    },
    data() {
    
    
    
    return {

        loading:true,
        nivel:"",
        icon:""
 
    }
    },
    computed: {
    
    },
    watch: {
    
    
    
    },
    created(){
    
    
    
    
    
    
    },
    mounted() {
    
        this.$eventBus.$on('parametroPromotor', (nivel,icon ) => {
      this.nivel = nivel;
      this.icon = icon;
      this.loading=false;
    });
    
    
    },
    methods: {
    }
    }
    
    </script>
    
    <style lang="scss">
    
    
    
    
    
    
    
    </style>
    